<template>
  <v-footer color="" >
    <v-row  no-gutters>
      
      <v-col class="d-flex justify-end">
      <v-btn
        text
        rounded
        :href="this.text_settings.privacy_url"
        color="grey"
        target="_blank"
        class="my-2"
      >
      
        {{this.text_settings.privacy_link_text}}
        
        
      </v-btn>
      <v-btn
        text
        rounded
        :href="this.text_settings.imprint_url"
        color="grey"
        target="_blank"
        class="my-2"
      >
      {{this.text_settings.imprint_link_text}}
    
      </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props:
    ['text_settings']
  ,
};
</script>
